<template>
  <div class="meritocracy-page">
    <div class="meritocracy-page__inner">
      <!-- Portfolio header title -->
      <div class="title-wrapper">
        <span class="title-wrapper__title">Meritocracy</span>
        <span class="title-wrapper__subtitle">Redesigning and rethinking the famous jobs portal, with a brand new website and administration dashboard for a better user experience.</span>
      </div>

      <div class="grid grid-cols-1 gap-12 mt-24">
        <!-- Landing -->
        <div class="portfolio-image-wrapper fadeUp" :class="{'show':showImages}">
          <img alt="meritocracy-landing" src="@/assets/portfolio/meritocracy/landing.webp">
        </div>
        <!-- Jobs List -->
        <div class="portfolio-image-wrapper fadeUp delay-200" :class="{'show':showImages}">
          <img alt="meritocracy-jobs" src="@/assets/portfolio/meritocracy/jobs.webp">
        </div>

        <!-- Jobs List Iphone -->
        <div class="meritocracy-responsive-box fadeUp" :class="{'show':showImages}">
          <div class="meritocracy-responsive-box__inner">
            <div class="flex">
              <span class="m-auto font-bold text-4xl md:text-5xl text-center py-6 md:py-0">Fully responsive</span>
            </div>
            <div class="flex">
              <img alt="meritocracy-jobs-responsive"
                   src="@/assets/portfolio/meritocracy/jobs-iphone.webp">
            </div>
          </div>
        </div>
        <!-- Company Page -->
        <div class="portfolio-image-wrapper fadeUp" :class="{'show':showImages}">
          <img alt="meritocracy-companypage" src="@/assets/portfolio/meritocracy/company-page.webp">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: "meritocracy",
  setup() {

    const showImages = ref(false)

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    onMounted(() => {
      setTimeout(function () {
        showImages.value = true;
      }, 100);
    })

    return {
      showImages
    }
  }
}
</script>

<style lang="scss" scoped>
.meritocracy-page {
  @apply w-full flex py-24;
  &__inner {
    @apply m-auto max-w-screen-xl px-4;

    .title-wrapper {
      @apply grid grid-cols-1 gap-6;

      &__title {
        color: $meritocracy-red;
        @apply font-bold text-5xl text-center;
        @screen md {
          @apply text-left text-7xl;
        }
      }

      &__subtitle {
        @apply text-xl text-center;

        @screen md {
          @apply text-left text-2xl;
        }
      }
    }


    .meritocracy-responsive-box {
      @apply flex w-full mb-12;

      @screen md {
        @apply mt-48;
      }

      &__inner {
        @apply grid grid-cols-1 md:grid-cols-2 gap-4 rounded-3xl w-full p-4 m-auto;
        background-color: #d2625d;

        img {
          @apply m-auto;
          height: 30rem;

          @screen md {
            @apply -mt-48;
          }
        }
      }
    }

    .portfolio-image-wrapper {
      max-width: 1680px;
      min-width: 320px;
      margin: 0 auto;

      div {
        margin-left: 0;
        margin-right: 0;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}
</style>